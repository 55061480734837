@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.Chargement {
  display: flex;
  align-items: center;
  margin: auto 30px;
}

.Roue {
  border-top: 6px solid #000;
  border-radius: 50%;
  width: 300px;
  height: 300px;
  animation: spin 1s linear infinite;
}

.Loading {
  font-size: 2rem;
  color: #000;
  transform: translateX(-125%);
}
