.App {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.description{
  margin-top: 100px;
  font-size: 20px;
  text-align: justify;
  width: 70%;
  overflow: auto;
}

.description img{
  width: 110px
}

.logosmusique{
  display: flex;
  justify-content: space-between;
  width: 50%;
  margin: 0 auto;
  cursor: pointer;
}