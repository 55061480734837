
.MuiDialog-paperScrollPaper
    max-width: none !important
.Dialog
    width: 90%
    margin: auto
    .DialogTitle
        font-size: 35px
        text-align: center
        font-weight: bold
        input
            width: 100%
        button
            padding: 10px 20px
            color: black
            margin: 10px 0
            font-size: 20px