.ModeJeu 
  padding: 20px
  background-color: #f0f0f0 
  border-radius: 8px 
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) 
  font-family: 'Arial', sans-serif 
  margin: 20px 0 

  select 
    margin-left: 10px 
    padding: 8px 12px 
    border-radius: 4px 
    border: 1px solid #ccc 
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1) 
    cursor: pointer 

    &:focus 
      outline: none 
      border-color: #007bff 



  div 
    margin-top: 15px 
    padding: 10px 
    background-color: white 
    border-left: 3px solid #007bff 
    font-size: 0.9rem 
    line-height: 1.4 

