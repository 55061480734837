.ListPlaylistCard 
    display: flex
    background: rgba(1, 31, 120, 0.751)
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 )
    backdrop-filter: blur( 10.5px )
    -webkit-backdrop-filter: blur( 10.5px )
    border-radius: 10px
    border: 1px solid rgba( 255, 255, 255, 0.18 )
    transition-duration: 0.2s
    color: rgb(255, 255, 255)
    font-family: -apple-system, BlinkMacSystemFont, sans-serif
    font-size: 35px
    font-weight: bold
    scrollbar-width: none
    justify-content: center
    align-items: center
    width: 100%
    height: 50px
    margin-bottom: 20px
    cursor: pointer
    &:hover 
        background: rgba(0, 19, 75, 0.751)


.PlaylistCard:hover 
    transform: scale(1.05)
    transition: transform 0.1s ease-in-out
