body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-image: url("background.jpg");
    background-size: cover;
    background-attachment: fixed;
    width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.logo{
  margin: 15px auto;
  display: block;
  position: fixed;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  height: 115px;
  width: auto;
}

button {
    background: rgba( 255, 255, 255, 0.15 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 10.5px );
    -webkit-backdrop-filter: blur( 10.5px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    color: rgb(255, 255, 255);
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
}

.content{
  width: 100%;
  margin-top: 110px;
}

h2{
  width: 100%;
  text-align: center;
  color: white;
  font-size: 40px;
  font-weight: bold;
  position: fixed;
}

.version{
  color: rgb(0, 0, 0, 0.5);
  font-size: 14px;
  position: fixed;
  bottom: 0px;
  left: 20px;
}

a{
  text-decoration: none;
}
