.VisuelQuestion 
  display: grid
  grid-template-columns: 1fr 1fr
  .PochetteAlbum 
    width: 450px
    height: 450px
    border-radius: 10px
    box-shadow: 0 0 10px #000
    margin-left: auto
    margin-right: 20px
  .infos 
    width: 100%
    margin-left: 20px
    display: flex
    flex-direction: column
    .TitrePlaylist 
      font-size: 2.7rem
      font-weight: bold
      color: white
      margin-top: 0
    button
      margin-top: 20px
      width: 230px
      height: 50px
      margin-right: 30px
      color: white
      font-size: 1.5rem
      font-weight: bold
      border: none
      cursor: pointer

.content
  display: flex
  flex-direction: column
  margin-top: 130px



.Question-Answered
  display: flex
  flex-direction: row
  margin-top: auto

.Response
  margin-top: auto
  margin-top: auto
.header 
  display: flex
  position: fixed
  justify-content: space-between
  width: 100%
  transform: translateY(35px)
  z-index: 1
  button
    padding: 10px 20px
    margin: 0 40px
    font-size: 1.2rem
    