.countdown 
  display: flex
  align-items: center
  justify-content: center
  margin: auto 30px
  position: relative
  width: 320px
  height: 320px

  .circle-container 
    position: absolute
    width: 100%
    height: 100%
    display: flex
    justify-content: center
    align-items: center

    .timeLeft 
      position: absolute
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)
      margin: 0
      font-size: 3rem
      z-index: 1
    

    svg 
      position: relative
      z-index: 0
    
  
