.PlaylistCard {
    background: rgba( 255, 255, 255, 0.15 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 10.5px );
    -webkit-backdrop-filter: blur( 10.5px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    transition-duration: 0.2s;
    cursor: pointer;
    height: 100%;
    width: 100%;
}

.PlaylistCard:hover {
    transform: scale(1.05);
    transition: transform 0.1s ease-in-out;
}

.titre {
    color: rgb(255, 255, 255);
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: 22px;
    font-weight: bold;
    display: -webkit-box;
    -webkit-box-orient: vertical;  
    line-clamp: 2;
    -webkit-line-clamp: 2;
    text-align: center;
    margin: 5px;
    overflow: hidden;
}


.logoplaylist{
    width: 75%;
    height: 75%;
    margin: 10px auto 0 auto;
    display: block;
    border-radius: 10px;
    object-fit: cover;
}

.done {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: green;
    font-size: 200px !important;
}

.Inline {
    width: 100%;
}

.PlaylistCard .DeletePlaylistIcon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}
  
/* Change la couleur de l'icône au survol */
.PlaylistCard .DeletePlaylistIcon:hover {
    color: red;
}
