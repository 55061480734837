
.MuiDialog-paperScrollPaper
    max-width: none !important
.Dialog
    width: 90%
    margin: auto
    .DialogTitle
        font-size: 35px
        text-align: center
        font-weight: bold

    .SplitDialogue
        display: flex
        justify-content: space-around
        .DialogueChoixGauche
            display: flex
            flex-direction: column
            width: 45%
        button
            background: rgba(0, 26, 103)
            box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 )
            backdrop-filter: blur( 10.5px )
            -webkit-backdrop-filter: blur( 10.5px )
            border-radius: 10px
            border: 1px solid rgba( 255, 255, 255, 0.18 )
            color: white
            font-size: 25px
            padding: 20px
            margin: 20px
.MuiAlert-root
    font-size: 20px !important
    font-weight: bold !important
.alert
    position: fixed
    left: 50%
    bottom: 20px
    transform: translateX(-50%)
    z-index: 1000