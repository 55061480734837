.secondaires
    font-family: -apple-system, BlinkMacSystemFont, sans-serif
    color: rgba(255, 255, 255, 0.8)
    font-size: 30px
    text-shadow: 1px 1px 5px rgba(31, 38,135,0.37)
    letter-spacing: 1px
    overflow: hidden
    width: 100%
    transition: 2s


.primaires
    font-family: -apple-system, BlinkMacSystemFont, sans-serif
    color: white
    font-size: 35px
    font-weight: bolder
    text-shadow: 1px 1px 5px rgba(31, 38,135,0.37)
    letter-spacing: 2px
    overflow: hidden
    width: 100%
    transition: 2s



.paroles
    font-size: 2em
    background: rgba( 255, 255, 255, 0.15 )
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 )
    backdrop-filter: blur( 10.5px )
    -webkit-backdrop-filter: blur( 10.5px )
    border-radius: 10px
    border: 1px solid rgba( 255, 255, 255, 0.18 )
    padding: 20px
    margin-right: 20px

.PLP
    display: grid
    grid-template-columns: 1fr 2fr
    margin: 0 40px
