@keyframes fadeIn 
  from 
    transform: translateX(-100%)
  to 
    transform: translateX(0)


.PlaylistSelection
    display: flex
    flex-direction: column

    .listPlaylists 
        display: flex
        flex-direction: row
        margin-top: 130px

        .catalogue
            width: 250px
            position: fixed
            left: 20px

        .PlaylistsPossibles
            flex-grow: 1
            display: grid
            grid-template-columns: repeat(auto-fill, minmax(20%, 1fr))
            gap: 20px
            margin-left: 290px
            margin-right: 340px

        .panneaudroite
            width: 300px
            position: fixed
            right: 20px
            bottom: 15px

            .Selection
                width: 300px
                top: 260px
                bottom: 90px
                height: auto
                position: fixed
                display: flex
                flex-direction: column
                background: rgba(1, 31, 120, 0.751)
                box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 )
                backdrop-filter: blur( 10.5px )
                -webkit-backdrop-filter: blur( 10.5px )
                border-radius: 10px
                border: 1px solid rgba( 255, 255, 255, 0.18)
                right: 20px

                .Carte_Selection
                    display: flex
                    justify-content: space-around
                    width: 100%
                    color: white
                    font-size: 40px
                    align-items: center
                    font-weight: bold
                    margin-top: 10px
                    .DeleteIconsButon
                        &:hover
                            color: red
                            
                .cartesSelectionnees
                    display: flex
                    flex-direction: column
                    margin: 20px
                    overflow-x: scroll
                    scrollbar-width: none
                    .cartes
                        display: grid
                        grid-template-columns: 40px 1fr 25px
                        gap: 10px
                        height: 40px
                        margin-bottom: 10px
                        background: rgba( 255, 255, 255, 0.15 )
                        box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 )
                        backdrop-filter: blur( 10.5px )
                        -webkit-backdrop-filter: blur( 10.5px )
                        border-radius: 10px
                        border: 1px solid rgba( 255, 255, 255, 0.18 )
                        color: rgb(255, 255, 255)
                        padding: 5px
                        align-items: center
                        animation: fadeIn 0.5s ease-out
                        .nomplaylistselection
                            display: flex
                            align-items: center
                            text-align: left
                            height: 38px
                            overflow: hidden
                        img
                            width: 100%
                            border-radius: 10%
                        .DeletePlaylist
                            cursor: pointer
                            .DeletePlaylistIcon
                                font-size: 20px
                                &:hover
                                    color: red
                .Validations
                    margin-top: auto
                    margin-bottom: 5px
                    display: flex
                    flex-direction: column
                    button
                        padding: 10px
                        font-size: 20px
                        margin: 5px 10px
            .Start
                width: 100%
                font-size: 50px
                &:hover
                    background: rgba(4, 75, 0, 0.751)
    .UserPlaylist
        position: fixed
        margin: 0 0
        width: 250px
        left: 20px
        bottom: 15px

.UserInfos 
    display: flex
    align-items: center
    position: fixed
    right: 10px
    top: 10px
    background-color: #f0f0f0
    padding: 10px
    border-radius: 8px
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1)
    width: 280px
    margin: 10px
    display: flex
    align-items: center
    cursor: pointer
    .UserImage 
        width: 60px
        height: 60px
        border-radius: 50%
        object-fit: cover
        margin-right: 20px
    .UserText
        display: flex
        flex-direction: column

    .UserName 
        font-size: 20px
        color: #333
        font-weight: bold
    .info 
        font-size: 14px
        color: #666
        margin-top: 8px

